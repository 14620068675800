import React from "react";
import Navigation from "../components/Navigation";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import CookieConsent from "react-cookie-consent";

import HeaderImg from "../images/main_banner.jpg";

const AboutPage = () => (
  <Layout>
    <SEO title="SLS Panamá" />

    <div
      style={{
        backgroundImage: `url(${HeaderImg})`,
        backgroundColor: `#ddd`,
        backgroundSize: `cover`
      }}
    >
      <div className="top_header pb-20">
        <Navigation />

        <div className="w-full text-center">
          <h1 className="minerva text-3xl lg:text-6xl text-white ml-5 lg:ml-0 mx-auto ">
            THE SLS STORY
          </h1>
        </div>
      </div>

      <div className="w-10/12 lg:w-8/12 mx-auto pb-20">
        <p className="minerva text-white text-lg mt-10 normal-case">
          An undocumented worker out of a studio apartment in Hollywood,
          California, in 2003 started a small E-commerce drop shipping.
        </p>
        <p className="minerva text-white text-lg   mt-10 normal-case">
          CeC “from the group up” developed an electronic TOOL that integrated
          the outsourcing to third-party providers the LOGISTICS SERVICES of the
          Fulfillment, and Customer Service, integrating to value-adding
          services related to the procurement of Inventory, Payment Integration,
          Accounting and Payroll Services.
        </p>
        <p className="minerva text-white text-lg   mt-10 normal-case">
          Integrated its twenty seven retail E-commerce Stores , with a core
          business of 3PSCM (third-party supply chain management provider) with
          over one hundred customers (Corporate and Individual, wholesale and
          retail), dispatching in average five thousand Parcels Per Day (USPS,
          UPS, FedEx, DHL), sold in 2010.
        </p>{" "}
        <p className="minerva text-white text-lg   mt-10 normal-case">
          In 2011 AINELY S.A. (Uruguay) was created to Develop Administrative
          Automation Software. With the network and experience did well
          automating payroll services and in 2017 opened operations in Panama
          under Servicios Logísticos y Soluciones de Panama S.A. Panama that has
          proven a good environment to focus on a Global Customer Base.
        </p>{" "}
        <p className="minerva text-white text-lg  mt-10 mb-10 normal-case">
          Automation is an absolute need for growth and SLS provides the
          Automation Solutions “from the group up ”.
        </p>
      </div>
    </div>
    <Footer />
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      cookieName="conscentcookie"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
      <span style={{ fontSize: "10px" }}>No personal data is stored.</span>
    </CookieConsent>
  </Layout>
);

export default AboutPage;
